import { NextPage } from 'next'
import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import 'react-notion-x/src/styles.css'
import { GraphqlProvider } from '@style-space/components/src/lib/graphql/GraphqlProvider'

import { theme } from '../themes'

const GlobalStyle = createGlobalStyle`
    * {
      font-family: Mukta, sans-serif;
      font-style: normal;
      box-sizing: border-box;
    }
    
    input {
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-appearance: none;
    }
    
    html {
      height: 100%;
    }
   
    body {
      margin: 0;
      position: relative;
      width: 100%;
      height: 100%;
    }
  `

type Props = {
  pageProps: any
  Component: NextPage
}

const MyApp: NextPage<Props> = ({ Component, pageProps }) => {
  return (
    <>
      <GraphqlProvider state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Component {...pageProps} />
        </ThemeProvider>
      </GraphqlProvider>
    </>
  )
}
MyApp.displayName = '_app'

export default MyApp
