import { DefaultTheme } from '@style-space/components/src/themes'
import { pxToRem } from '@style-space/components/src/lib/utils'

export const theme = {
  ...DefaultTheme,
  images: {},
  breakpoint: {
    tabletV: pxToRem(640),
    tabletH: pxToRem(960),
    laptop: pxToRem(1280),
    desktop: pxToRem(1600),
  },
}
